.neuralNetworkInterface {
  display: flex;
  flex-direction: row;
  background-color: #222;
}

.neuralNetworkInterface .left {
  width: 50%;
  padding: 50px;
  padding-top: 20px;
}

.neuralNetworkInterface .right {
  width: 50%;
  padding: 50px;
  padding-top: 20px;
}

.neuralNetworkInterface .right.faded {
  background-color: #444;
}