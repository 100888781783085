.section {
  display: flex;
  justify-content: center;
  background-color: #222;
}

.section.alt {
  background-color: #181818;
}

.section .left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.section .sectionImage {
  width: 300px;
}

.section .right {
  padding: 50px;
  display: flex;
  max-width: 600px;
  flex-direction: column;
}

.section .title {
  font-size: 23px;
  color: #eee;
  margin-bottom: 35px;
}

.section .content {
  font-size: 17px;
  color: #eee;
  margin-bottom: 20px;
  text-align: justify;
}

.section .subtext {
  color: #ccc;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

.section .subtext .finalText {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}