
.imageHeader {
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imageHeader .imageTitle {
  color: white;
  font-size: 45px;
  margin-bottom: 25px;
}

.imageHeader .imageDescription {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 60%;
}
